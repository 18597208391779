export const useSidebarStore = defineStore("sidebar", () => {
    const forceOpen: Ref<boolean> = ref(false);

    const toggleSidebar = () => {
        forceOpen.value = !forceOpen.value;
    };

    const closeSidebar = () => {
        forceOpen.value = false;
    };

    return {
        forceOpen,
        toggleSidebar,
        closeSidebar,
    };
});
