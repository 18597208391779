<template>
    <a
        class="inline-block py-5 px-7 cursor-pointer"
        :title="$t('toggle_navigation')"
        @click="toggle()"
    >
        <icon-burger
            class="w-5 h-auto"
            filled
            :class="{ 'fill-white': white }"
        />
    </a>
</template>

<script setup lang="ts">
import IconBurger from "~/public/icons/burger.svg";

const sidebarStore = useSidebarStore();

defineProps({
    white: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["toggle"]);

const toggle = () => {
    sidebarStore.toggleSidebar();
    emit("toggle");
};
</script>
